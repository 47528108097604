@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #4a90e2;
  --secondary-color: #50e3c2;
  --accent-color: #f39c12;
  --background-color: #f0f4f8;
  --text-color: #333333;
  --light-text-color: #ffffff;
  --accent-color-rgb: 243, 156, 18;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header and Navigation Styles */
.header {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  padding: 1rem 0;
  position: relative;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  display: flex;
  text-decoration: none;
  align-items: center;
  font-weight: bold;
  font-size: 1.8rem;
}

.logo-icon {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 2px;
}

.logo-letter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--light-text-color);
  font-size: 1.5rem;
  font-weight: 700;
  z-index: 2;
}

.logo-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--accent-color), var(--secondary-color));
  border-radius: 50%;
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.logo-icon::before,
.logo-icon::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.logo-icon::after {
  filter: blur(5px);
}

.nav-logo:hover .logo-circle {
  transform: scale(0.9);
}

.nav-logo:hover .logo-icon::before,
.nav-logo:hover .logo-icon::after {
  opacity: 1;
}

.logo-text {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  position: relative;
  overflow: hidden;
}

.logo-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: left 0.5s ease;
}

.nav-logo:hover .logo-text::before {
  left: 100%;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--accent-color-rgb), 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--accent-color-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--accent-color-rgb), 0);
  }
}

.logo-icon {
  animation: pulse 2s infinite;
}

.nav-menu {
  display: flex;
  list-style-type: none;
}

.nav-menu, .nav-menu ul {
  list-style: none;
}

.nav-menu li {
  list-style-type: none;
}

.nav-menu > li {
  position: relative;
}

.nav-menu li a {
  color: var(--light-text-color);
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem 1rem;
  display: block;
  transition: background-color 0.3s ease;
}

.nav-menu li a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--primary-color);
  min-width: 200px;
  display: none;
  z-index: 1;
}

.nav-menu li:hover .dropdown {
  display: block;
}

.dropdown li {
  width: 100%;
}

.dropdown li a {
  padding: 0.75rem 1rem;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: var(--light-text-color);
}

/* Hero Section Styles */
.hero {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--light-text-color);
  text-align: center;
  padding: 100px 0;
  margin-bottom: 60px;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-out;
}

.hero p {
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto;
  animation: fadeInUp 1s ease-out 0.5s both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Tool Cards Styles */
.tools {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 40px 0;
}

.tool-card {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, transparent, rgba(255,255,255,0.3), transparent);
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.tool-card:hover::before {
  left: 100%;
  top: 100%;
}

.tool-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.tool-card h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.tool-card p {
  font-size: 16px;
  color: #666;
}

/* Tool Page Styles */
.tool-page {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-top: 40px;
}

.tool-page h1 {
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.file-input {
  display: none;
}

.file-label {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-label:hover {
  background-color: #3a7bc8;
}

.file-info {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.convert-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 20px auto;
  padding: 10px;
  background-color: var(--accent-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.convert-button:hover {
  background-color: #e67e22;
}

.convert-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Results Styles */
.result {
  margin-top: 40px;
  text-align: center;
}

.result h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.result-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.result-item h3 {
  margin-bottom: 10px;
}

.thumbnail-link {
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}

.thumbnail-link img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.result-item:hover {
  transform: translateY(-5px);
}

.result-item svg {
  margin-bottom: 15px;
}

.result-item img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.results {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  text-decoration: none;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: 600;
}

.download-button:hover {
  background-color: #3a7bc8;
  transform: translateY(-2px);
}

.watermark-notice {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  font-style: italic;
}

/* Footer Styles */
footer {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  padding: 60px 0;
  margin-top: 80px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin-right: 40px;
  margin-bottom: 30px;
}

.footer-section h3 {
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--secondary-color);
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: var(--light-text-color);
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.footer-section ul li a:hover {
  opacity: 0.8;
}

.footer-bottom {
  text-align: center;
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid rgba(255,255,255,0.1);
}

/* Format Selection Styles */
.format-selection {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.format-selection label {
  margin: 0 10px;
  cursor: pointer;
}

.format-selection input[type="radio"] {
  margin-right: 5px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-menu {
    position: absolute;
    left: -100%;
    top: 100%;
    flex-direction: column;
    background-color: var(--primary-color);
    width: 100%;
    text-align: left;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    z-index: 10;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-menu > li {
    margin: 0;
    width: 100%;
  }

  .dropdown {
    position: static;
    display: none;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .dropdown.show {
    display: block;
  }

  .nav-menu li a {
    padding: 0.75rem 1rem;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .hero {
    padding: 50px 0;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero p {
    font-size: 16px;
  }

  .tools {
    grid-template-columns: 1fr;
  }

  .tool-page {
    padding: 20px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 15px;
  }

  .hero h1 {
    font-size: 28px;
  }
} /* Add this closing bracket */